import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-4 xl:px-0" }

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import PoolsTable from '@/components/tables/PoolsTable/PoolsTable.vue';
import useStaking from '@/composables/staking/useStaking';
import { isL2 } from '@/composables/useNetwork';
import { configService } from '@/services/config/config.service';
import useWeb3 from '@/services/web3/useWeb3';

/** COMPOSABLES */

export default _defineComponent({
  setup(__props) {

const {
  userData: {
    stakedPools,
    isLoadingUserStakingData,
    isLoadingStakedPools,
    isLoadingUserPools,
    poolBoosts,
  },
} = useStaking();
const { isWalletReady, isWalletConnecting } = useWeb3();
const { t } = useI18n();
const networkName = configService.network.shortName;

/** COMPUTED */
const isLoading = computed(() => {
  return (
    isLoadingUserStakingData.value ||
    isLoadingStakedPools.value ||
    isLoadingUserPools.value
  );
});

const noPoolsLabel = computed(() => {
  return isWalletReady.value || isWalletConnecting.value
    ? t('noStakedInvestments', [networkName])
    : t('connectYourWallet');
});

const poolsWithBoost = computed(() => {
  return stakedPools.value.map(pool => ({
    ...pool,
    boost: (poolBoosts.value || {})[pool.id],
  }));
});

const hiddenColumns = computed(() => {
  const _hiddenColumns = [
    'poolVolume',
    'poolValue',
    'migrate',
    'actions',
    'lockEndDate',
  ];
  if (isL2.value) _hiddenColumns.push('myBoost');
  return _hiddenColumns;
});

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BalStack, {
      vertical: "",
      spacing: "sm"
    }, {
      default: _withCtx(() => [
        _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.$t('staking.stakedPools')), 1),
        _createVNode(PoolsTable, {
          key: _unref(poolsWithBoost),
          data: _unref(poolsWithBoost),
          noPoolsLabel: _unref(noPoolsLabel),
          hiddenColumns: _unref(hiddenColumns),
          isLoading: _unref(isLoading),
          showPoolShares: "",
          showBoost: ""
        }, null, 8, ["data", "noPoolsLabel", "hiddenColumns", "isLoading"])
      ]),
      _: 1
    })
  ]))
}
}

})