import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-4 xl:px-0" }

import { computed } from 'vue';

import PoolsTable from '@/components/tables/PoolsTable/PoolsTable.vue';
import { useLock } from '@/composables/useLock';
import { VeBalLockInfo } from '@/services/balancer/contracts/contracts/veBAL';
import { Pool, PoolWithShares } from '@/services/pool/types';

/**
 * PROPS
 */
type Props = {
  lockPool: Pool;
  lock: VeBalLockInfo;
};

export default _defineComponent({
  props: {
    lockPool: { type: null, required: true },
    lock: { type: null, required: true }
  } as unknown as undefined,
  setup(__props: {
  lockPool: Pool;
  lock: VeBalLockInfo;
}) {

const props = __props


/** COMPOSABLES */
const { lockedFiatTotal } = useLock();

/** COMPUTED */
const lockPools = computed<PoolWithShares[]>(() => {
  if (props.lockPool) {
    return [
      {
        ...props.lockPool,
        bpt: '',
        shares: lockedFiatTotal.value,
        lockedEndDate:
          props.lock?.hasExistingLock && !props.lock?.isExpired
            ? props.lock?.lockedEndDate
            : undefined,
      },
    ];
  }
  return [];
});

const hiddenColumns = [
  'poolVolume',
  'poolValue',
  'migrate',
  'actions',
  'myBoost',
];

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BalStack, {
      vertical: "",
      spacing: "sm"
    }, {
      default: _withCtx(() => [
        _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.$t('veBalProtocolLiquidity')), 1),
        _createVNode(PoolsTable, {
          key: _unref(lockPools),
          data: _unref(lockPools),
          hiddenColumns: hiddenColumns,
          showPoolShares: ""
        }, null, 8, ["data"])
      ]),
      _: 1
    })
  ]))
}
}

})