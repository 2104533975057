import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, normalizeClass as _normalizeClass, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d7f356a0")
const _hoisted_1 = ["textContent"]
const _hoisted_2 = {
  key: 1,
  class: "mb-1 text-3xl font-semibold text-white"
}
const _hoisted_3 = {
  key: 2,
  class: "relative mt-2 inline-block"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
_popScopeId()

import { computed } from 'vue';
import { useRouter } from 'vue-router';

import AppHero from '@/components/heros/AppHero.vue';
import usePools from '@/composables/pools/usePools';
import useStaking from '@/composables/staking/useStaking';
import { useLock } from '@/composables/useLock';
import { isL2 } from '@/composables/useNetwork';
import useNumbers, { FNumFormats } from '@/composables/useNumbers';
import { bnum } from '@/lib/utils';
import useWeb3 from '@/services/web3/useWeb3';

import HeroConnectWalletButton from './HeroConnectWalletButton.vue';

/**
 * COMPOSABLES
 */

export default _defineComponent({
  setup(__props) {

const router = useRouter();
const { fNum2 } = useNumbers();
const { isWalletReady, isWalletConnecting } = useWeb3();
const { totalInvestedAmount, isLoadingUserPools } = usePools();
const { lockFiatValue, isLoadingLock } = useLock();
const {
  userData: {
    totalStakedFiatValue,
    isLoadingUserStakingData,
    isLoadingStakedPools,
    isUserStakeDataIdle,
  },
} = useStaking();

/**
 * COMPUTED
 */
const classes = computed(() => ({
  ['h-48']: !isWalletReady.value && !isWalletConnecting.value,
  ['h-44']: isWalletReady.value || isWalletConnecting.value,
}));

const isStakingLoading = computed(() => {
  return (
    isLoadingStakedPools.value ||
    isLoadingUserStakingData.value ||
    isUserStakeDataIdle.value
  );
});

const totalInvestedLabel = computed((): string => {
  const value = bnum(totalInvestedAmount.value || '0')
    .plus(lockFiatValue.value)
    .plus(totalStakedFiatValue.value)
    .toString();
  return fNum2(value, FNumFormats.fiat);
});

const totalVeBalLabel = computed((): string =>
  fNum2(lockFiatValue.value, FNumFormats.fiat)
);

const isLoadingLockAndStaking = computed(
  (): boolean => (!isL2.value && isLoadingLock.value) || isStakingLoading.value
);

const isLoadingTotalValue = computed(
  (): boolean => isLoadingUserPools.value || isLoadingLockAndStaking.value
);

return (_ctx: any,_cache: any) => {
  const _component_BalLoadingBlock = _resolveComponent("BalLoadingBlock")!

  return (_openBlock(), _createBlock(AppHero, {
    class: _normalizeClass(_unref(classes))
  }, {
    default: _withCtx(() => [
      _createElementVNode("h1", {
        class: "mb-2 font-body text-base font-medium text-white opacity-90",
        textContent: _toDisplayString(_ctx.$t('myBalancerInvestments'))
      }, null, 8, _hoisted_1),
      (_unref(isWalletReady) || _unref(isWalletConnecting))
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            (_unref(isLoadingTotalValue))
              ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                  key: 0,
                  class: "mx-auto h-10 w-40",
                  white: ""
                }))
              : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_unref(totalInvestedLabel)), 1)),
            (!_unref(isL2))
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_unref(isLoadingTotalValue))
                    ? (_openBlock(), _createBlock(_component_BalLoadingBlock, {
                        key: 0,
                        class: "mx-auto h-8 w-40",
                        white: ""
                      }))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: "vebal-banner group flex h-8 cursor-pointer items-center rounded-tr rounded-bl border border-yellow-500 px-3 text-sm font-medium text-yellow-500 transition-colors hover:text-white focus:text-white",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: 'vebal' })))
                      }, [
                        (_unref(lockFiatValue) === '0')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_unref(lockFiatValue)) + " " + _toDisplayString(_ctx.$t('veBAL.hero.tokens.veBAL')), 1))
                          : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.$t('inclXInVeBal', [_unref(totalVeBalLabel)])), 1))
                      ]))
                ]))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createBlock(HeroConnectWalletButton, {
            key: 1,
            class: "mt-4"
          }))
    ]),
    _: 1
  }, 8, ["class"]))
}
}

})