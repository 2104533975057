import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pt-10 md:pt-12 xl:container xl:mx-auto xl:px-4" }
const _hoisted_2 = { class: "px-4 xl:px-0" }
const _hoisted_3 = { class: "px-4 xl:px-0" }

import { computed } from 'vue';

import StakedPoolsTable from '@/components/contextual/pages/pools/StakedPoolsTable.vue';
import UnstakedPoolsTable from '@/components/contextual/pages/pools/UnstakedPoolsTable.vue';
import VeBalPoolTable from '@/components/contextual/pages/pools/VeBalPoolTable.vue';
import PortfolioPageHero from '@/components/heros/PortfolioPageHero.vue';
import PoolsTable from '@/components/tables/PoolsTable/PoolsTable.vue';
import usePoolFilters from '@/composables/pools/usePoolFilters';
import usePools from '@/composables/pools/usePools';
import { useLock } from '@/composables/useLock';
import { isMigratablePool } from '@/composables/usePool';
import StakingProvider from '@/providers/local/staking/staking.provider';

// COMPOSABLES


export default _defineComponent({
  setup(__props) {

const { selectedTokens } = usePoolFilters();

const { userPools, isLoadingUserPools } = usePools();

const { lockPool, lock } = useLock();

const migratableUserPools = computed(() => {
  return userPools.value.filter(pool => isMigratablePool(pool));
});

return (_ctx: any,_cache: any) => {
  const _component_BalStack = _resolveComponent("BalStack")!

  return (_openBlock(), _createBlock(_unref(StakingProvider), null, {
    default: _withCtx(() => [
      _createVNode(PortfolioPageHero),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BalStack, { vertical: "" }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_BalStack, {
                horizontal: "",
                justify: "between",
                align: "center"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('myInvestments')), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode(_component_BalStack, {
              vertical: "",
              spacing: "2xl"
            }, {
              default: _withCtx(() => [
                _createVNode(UnstakedPoolsTable),
                _createVNode(StakedPoolsTable),
                (_unref(lockPool) && Number(_unref(lock)?.lockedAmount) > 0)
                  ? (_openBlock(), _createBlock(VeBalPoolTable, {
                      key: 0,
                      lock: _unref(lock),
                      lockPool: _unref(lockPool)
                    }, null, 8, ["lock", "lockPool"]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", null, [
                  (_unref(migratableUserPools).length > 0)
                    ? (_openBlock(), _createBlock(_component_BalStack, {
                        key: 0,
                        vertical: "",
                        spacing: "sm"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.$t('poolsToMigrate')), 1),
                          _createVNode(PoolsTable, {
                            key: _unref(migratableUserPools),
                            isLoading: _unref(isLoadingUserPools),
                            data: _unref(migratableUserPools),
                            noPoolsLabel: _ctx.$t('noInvestments'),
                            showPoolShares: "",
                            selectedTokens: _unref(selectedTokens),
                            hiddenColumns: [
                  'poolVolume',
                  'poolValue',
                  'actions',
                  'lockEndDate',
                ]
                          }, null, 8, ["isLoading", "data", "noPoolsLabel", "selectedTokens"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }))
}
}

})